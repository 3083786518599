<template>
  <el-dialog
    class="template-header"
    title=""
    width="50%"
    :before-close="handleClose"
    :visible.sync="isOpenRulesTemplatePop"
  >
    <!-- v-infinite-scroll="load" -->
    <el-header>
      <div>
        <el-input
          class="customInput"
          v-model="filterParams.keyword"
          placeholder="标题关键词检索"
          clearable
          ><i
            slot="prefix"
            class="el-input__icon el-icon-search"
          ></i
        ></el-input>
        <el-select
          v-model="filterParams.platform"
          placeholder="请选择平台"
          clearable
        >
          <el-option
            v-for="item in platformList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="filterParams.target_type"
          placeholder="请选择作用对象"
        >
          <el-option
            v-for="item in targetTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button
          @click="handleFind"
          type="primary"
          class="ml-20"
          >查询</el-button
        >
        <el-button @click="handleReset">重置</el-button>
      </div>
    </el-header>
    <el-skeleton
      class="skeleton"
      :rows="15"
      :loading="loading"
      animated
    >
      <ul
        class="infinite-list"
        style="overflow: auto"
        v-loading="loading"
      >
        <li
          v-for="(item, index) in templateList"
          :key="index"
          class="infinite-list-item"
        >
          <el-card
            :class="checkedCard == index ? 'box-card' : 0"
            shadow="hover"
          >
            <div
              slot="header"
              class="dp-flex justify-btw"
              @click.stop="
                (val) => {
                  selectCard(val, index);
                }
              "
            >
              <span class="card-title"
                ><el-radio
                  v-model="checkedCard"
                  :label="index"
                  >{{ item.title }}</el-radio
                ></span
              >
              <span class="detail">
                <span>平台：{{ item.platform }} </span>
                <span>作用对象：{{ getTargetType(item.target_type) }} </span>
                <!-- <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              > -->
              </span>
            </div>
            <el-checkbox-group v-model="item.checkedTabs">
              <el-checkbox
                v-for="(ruleItems, ruleIndex) in item.spec.rule_items"
                :label="ruleItems"
                :key="ruleIndex"
                :disabled="checkedCard !== index"
                >{{ ruleItems.name }}：
                <span
                  v-for="(metric, metricIndex) in ruleItems.metric_filters"
                  :key="metricIndex"
                >
                  {{ metric.desc }}
                  <span v-if="metricIndex != ruleItems.metric_filters.length - 1">且</span>
                </span>
                <span>，{{ ruleItems.action_type }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-card>
        </li>
      </ul>
      <el-empty v-if="templateList.length == 0" description="暂无数据"></el-empty>
    </el-skeleton>
    <div class="dp-flex justify-btw mt-20">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="paging.total_count"
        :page-count="paging.total_pages"
        :current-page="filterParams.page_num"
        :page-size="filterParams.page_size"
        :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
        @current-change="changePage"
        @size-change="handleSizeChange"
      >
      </el-pagination>
      <el-button
        type="primary"
        @click="handelUseTemplate"
        >下一步</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { rulesTemplate } from '@/api/automaticRules';
import _ from 'lodash';
import { isArray } from 'ali-oss/lib/common/utils/isArray';
import commonData from './commonData'
export default {
  mixins:[commonData],
  data() {
    return {
      isOpenRulesTemplatePop: false,
      templateList: [],
      checkedCard: 0, //选中的卡片
      // checkedName:'',//选中的名称
      checkAll: false,
      // isIndeterminate: true,
      loading: true,
      filterParams: {
        keyword: '',
        platform: '',
        target_type: '',
        page_num: 1,
        page_size: 10,
      },
      paging: {},
    };
  },
  methods: {
    selectCard(val, index) {
      console.log(index);
      this.checkedCard = index;
      // this.checkedName = this.templateList[index].title;
      this.templateList[index].checkedTabs = this.templateList[index].spec.rule_items;
    },
    handelUseTemplate() {
      console.log(this.templateList[this.checkedCard].checkedTabs.length);
      if (this.templateList[this.checkedCard].checkedTabs.length == 0) {
        this.$message.info('请至少选择一条');
        return;
      }
      this.templateList[this.checkedCard].spec.rule_items = _.cloneDeep(
        this.templateList[this.checkedCard].checkedTabs,
      );
      this.templateList[this.checkedCard].spec.rule_items.map((v) => {
        if (!v.hasOwnProperty('change_budget')) {
          let changeBudget = {
            budget: '提高',
            //总预算
            budget_type: '日预算', //预算类型 日预算
            change_type: 'AMOUNT', //改变类型 AMOUNT ，加金额，PERCENT 加百分比
            change_value: 0, //金额或百分比
            max_value: '', //预算上限
            min_value: '', //预算下限
          };
          this.$set(v, 'change_budget', changeBudget);
        }
      });
      console.log(this.templateList[this.checkedCard]);
      this.$emit('getTemplateList', this.templateList[this.checkedCard]);
      this.isOpenRulesTemplatePop = false;
    },
    //调用模板列表
    rulesTemplate() {
      this.loading = true;
      let paramsToSend = {};
      if (this.filterParams.platform) {
        paramsToSend.platform = this.filterParams.platform;
      }
      if (this.filterParams.target_type) {
        paramsToSend.target_type = this.filterParams.target_type;
      }
      paramsToSend.keyword = this.filterParams.keyword;
      paramsToSend.page_number = this.filterParams.page_num;
      paramsToSend.page_size = this.filterParams.page_size;
      rulesTemplate(paramsToSend).then((res) => {
        this.loading = false;
        if (res.code === 0) {
          if (res.data.paging.total_count != 0) {
            if (res.data.contents) {
              this.templateList = res.data.contents;
              if (isArray(this.templateList) && this.templateList.length > 0) {
                this.templateList.map((v, index) => {
                  if (!v.hasOwnProperty('checkedTabs')) {
                    if (index == 0) {
                      this.$set(v, 'checkedTabs', v.spec.rule_items);
                    } else {
                      this.$set(v, 'checkedTabs', []);
                    }
                  }
                });
              }
            }
          } else {
            this.templateList = [];
          }
          this.paging = res.data.paging;

        }
      });
    },
    // load() {
    //   console.log('load');
    //   this.rulesTemplate();
    // },
    // handleCheckAllChange(val) {
    // this.checkedCities = val ? cityOptions : [];
    // this.isIndeterminate = false;
    // },
    // handleCheckedChange(value) {
    // let checkedCount = value.length;
    // this.checkAll = checkedCount === this.cities.length;
    // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    // },
    //查询按钮
    handleFind() {
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
      this.rulesTemplate();
    },
    //重置按钮
    handleReset() {
      this.reset();
      this.rulesTemplate();
    },
    //重置 初始化 清空
    reset() {
      this.filterParams.keyword = '';
      this.filterParams.platform = '';
      this.filterParams.target_type = '';
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
      this.checkedCard = 0;
    },
    //改变一页条数
    handleSizeChange(v) {
      this.filterParams.page_size = v;
      this.checkedCard = 0;
      this.rulesTemplate();
    },
    //改变页数
    changePage(v) {
      this.filterParams.page_num = v;
      this.checkedCard = 0;
      this.rulesTemplate();
    },
    handleClose(done) {
      done();
    },
    handleOpen() {
      this.isOpenRulesTemplatePop = true;
      this.checkedCard = 0;
      this.rulesTemplate();
    },
    getTargetType(key) {
      switch (key) {
        case 'campaign':
          return '广告系列';
        case 'adset':
          return '广告组';
        default:
          return key;
      }
    },
    getMetricFilters(key) {
      switch (key) {
        case 'checkout':
          return '加购';
        case 'order_count':
          return '订单';
        case 'spend':
          return '花费';
        case 'cp_order':
          return '订单成本';
        case 'cp_checkout':
          return '加购成本';
        case 'pre_profit_rate':
          return '毛利率';
        case 'clicks':
          return '点击';
        case 'impressions':
          return '展示';
        case 'ctr':
          return '点击率';
        case 'cpc':
          return '点击成本';
        case 'cpm':
          return '千次展示成本';
        default:
          return key;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  background: #ededed;
}

.template-header {
  .el-dialog {
    min-width: 1000px;
    .el-dialog__header {
      margin-top: -60px;
    }
  }
}
.skeleton {
  height: 60vh;
  margin-top: 20px;
  padding: 0 20px;
}
.infinite-list {
  max-height: 60vh;
  padding-right: 20px;
  .infinite-list-item {
    margin-bottom: 20px;
    .card-title {
      color: #409eff;
      font-weight: bold;
      font-size: 16px;
    }
    .detail {
      color: #999999;
    }
    .box-card {
      border: 1px solid #409eff;
    }
    .el-card__body {
      padding: 20px 20px 10px;
    }
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      margin-bottom: 20px;
    }
  }
}
</style>
