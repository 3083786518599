<template>
  <el-drawer
    title="执行日志"
    :visible.sync="isOpenLogDraw"
    direction="rtl"
    :size="800"
  >
    <div class="logDraw">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        ><el-table-column
          label="序号"
          type="index"
          width="50"
        ></el-table-column>
        <el-table-column
          label="执行结果"
          type="index"
          width="90"
          ><template slot-scope="scope">
            <div class="dp-flex align-item-c">
              <div :class="['dot', scope.row.status === 'FAILED' ? 'redDot' : 'greenDot']"></div>
              <div>{{ formatState(scope.row.status) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width ? item.width : '180px'"
          v-for="(item, index) in tableColumnData"
          :key="index"
        >
        </el-table-column
      ></el-table></div
  ></el-drawer>
</template>

<script>
import { runList } from '@/api/automaticRules';

export default {
  data() {
    return {
      isOpenLogDraw: false,
      tableData: [],
      tableColumnData: [
        {
          prop: 'schedule_at',
          label: '触发时间',
        },
        {
          prop: 'start_at',
          label: '开始时间',
        },
        {
          prop: 'duration',
          label: '执行时间',
          width: '80px',
        },
        {
          prop: 'finished_at',
          label: '结束时间',
        },
      ],
    };
  },
  methods: {
    //格式化状态
    formatState(key) {
      switch (key) {
        case 'SUCCESS':
          return '成功';
        case 'STARTED':
          return '已启动';
        case 'FAILED':
          return '失败';
        default:
          return key;
      }
    },
    //调用执行列表接口获取数据
    runList(id) {
      const param = { rule_id: id };
      runList(param).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.tableData = res.data;
        }
      });
    },
    //打开弹窗
    openLogDraw() {
      this.isOpenLogDraw = true;
    },
    //关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style scoped>
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}
.greenDot {
  background-color: #66cc66;
}

.redDot {
  background-color: #cc3300;
}
.logDraw {
  padding: 20px;
}
</style>
