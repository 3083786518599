<template>
  <div class="popoverWrap">
    <!-- <el-popover
      ref="expressionPopover"
      placement="top-end"
      trigger="manual"
      width="550"
      v-model="expressionVisible"
    > -->
    <div
      v-if="expressionVisible"
      class="popover"
    >
      <div class="small-gray lh-20"><i class="el-icon-question" /> 表达式说明：</div>
      <div class="small-gray lh-20">1. 支持数字、变量、运算符等</div>
      <div class="small-gray lh-20">2. 变量：有日期项和属性项。示例：#日预算、#当天_花费、#当天_点击率。<br/>日预算、目标出价、竞价可以不搭配时间，其他属性项必须搭配时间。<br/>变量可以选择，可以输入。输入时按下空格再输入会有提示。<br/>有空格、#、_等字符是帮助程序检测，不需要删除</div>
      <div class="small-gray lh-20">3. 运算符：+、-、*、/、() 均为英文字符</div>
      <div class="small-gray lh-20">4. 示例：#日预算*0.8+(#当天_花费*0.8-#当天_点击率)</div>
      <div class="dp-flex align-content">
        <el-cascader
          placeholder="请选择要插入的时间和属性"
          v-model="selectValue"
          :options="selectOptionList"
          :props="{ value: 'name', children: 'children', label: 'name', expandTrigger: 'hover' }"
          @change="handleCommand"
          filterable
          clearable
        ></el-cascader>
        <el-button-group class="mb-10">
          <el-button
            v-for="item in optionList"
            @click="insertContent(item)"
            >{{ item }}</el-button
          >
        </el-button-group>
      </div>
      <!-- expressionValue{{ expressionValue }} -->
      <div class="databaseModelingOnLogic">
        <sql-template
          ref="cm"
          :hintList="hintList"
          v-model="expressionValue"
          :hintLabel="'name'"
          :splitRules="[' ']"
          :isHint="true"
        ></sql-template>
      </div>
      <div class="mt-10 text-a-r">
        <el-button
          size="mini"
          type="text"
          @click="expressionVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="f_sure"
          >确定</el-button
        >
      </div>
      <div
        slot="reference"
        @click="showPopover"
      ></div>
    </div>
  </div>
</template>

<script>
import SqlTemplate from './SqlTemplate.vue';
export default {
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
    metricList: {
      type: Array,
      default: () => [],
    },
    metricIndex: {
      type: Number,
      default: 0,
    },
    ruleItemsIndex: {
      type: Number,
      default: 0,
    },
  },
  components: { SqlTemplate },
  data() {
    return {
      expressionVisible: false,
      expressionValue: '',
      selectValue: '',
      optionList: ['(', ')', '+', '-', '*', '/'], //操作符号
      metricFiltersList: [], //属性项
      hintList: [], //搜索范围
      selectOptionList: [],
    };
  },
  mounted() {
    let list = this.deleteLastElement(this.metricList).map((item) => {
      if (!item.hasOwnProperty('isNeedDate')) {
        item.isNeedDate = true;
      }
      return {
        name: item.desc,
        isNeedDate: item.isNeedDate,
      };
    });
    this.metricFiltersList = [...list];
    this.hintList = [
      ...this.metricFiltersList.map((item) => {
        if (item.isNeedDate) {
          return { name: `_${item.name} ` };
        } else {
          return { name: ` #${item.name} ` };
        }
      }),
      ...this.dateList.map((item) => {
        return {
          name: ` #${item} `,
        };
      }),
    ];
    this.selectOptionList = this.dateList.map((item) => {
      return {
        name: `#${item}`,
        children: this.metricFiltersList,
      };
    });
  },
  methods: {
    f_sure() {
      if (!this.expressionValue) {
        return this.$message.warning(`表达式不能为空`);
      }
      //将中文符号替换为英文
      this.expressionValue = this.replaceChineseSymbols(this.expressionValue);
      let str = this.expressionValue.replace(/(?<!\s)#/g, ' #'); // 在没有空格的#前面添加空格
      str = str.replace(/(#[\u4e00-\u9fa5_\d]+)(?!\s)(?=[^\u4e00-\u9fa5_\d])/g, '$1 '); // 在没有空格的#后面添加空格
      this.expressionValue = str;
      //判断_后面是不是汉字
      if (!this.check_Chinese(this.expressionValue)) {
        this.$message.error(`表达式有误,请检查`);
        return;
      }
      //遍历出#和_后面的内容，查看是不是属性项或者日期项
      if (!this.checkIsDateOrMetric(this.expressionValue)) {
        this.$message.error(`表达式有误,请检查`);
        return;
      }
      //将#后面的都替换成1，看输出是否是一个数字
      let newStr = this.expressionValue.replace(/#[\u4e00-\u9fa5_\d]+/g, '1');
      try {
        console.log(isNaN(new Function(`return ${newStr}`)()));
      } catch (error) {
        // console.log(error, 'error');
        this.$message.error('表达式有误,请检查');
        return;
      }
      //检查通过
      this.$emit('f_expressionSure', this.ruleItemsIndex, this.metricIndex, this.expressionValue);
      this.expressionVisible = false;
    },
    //遍历出#和_后面的内容，查看是不是属性项或者日期项
    checkIsDateOrMetric(str) {
      let hashMatches = str.match(/#([\u4e00-\u9fa5\d]+)/g) ? str.match(/#([\u4e00-\u9fa5\d]+)/g) : [];
      let underscoreMatches = str.match(/_([\u4e00-\u9fa5]+)/g) ? str.match(/_([\u4e00-\u9fa5]+)/g) : [];

      if (hashMatches) {
        hashMatches = hashMatches.map((item) => item.slice(1)); // 去掉前面的#
      }
      if (underscoreMatches) {
        underscoreMatches = underscoreMatches.map((item) => item.slice(1)); // 去掉前面的_
      }
      //被检查的数组
      const uniqueArray = Array.from(new Set([...hashMatches, ...underscoreMatches]));
      //所有属性项和日期项
      let allList = [
        ...this.dateList,
        ...this.metricFiltersList.map((item) => {
          return item.name;
        }),
      ];
      let result = uniqueArray.every((item) => {
        if (!allList.includes(item)) {
          // console.log(item); // 输出不在arr2中的元素
          return false;
        }
        return true;
      });
      return result;
    },
    //判断_后面是不是汉字
    check_Chinese(str) {
      let matches = str.match(/_([\u4e00-\u9fa5]*)/g);

      let is_AllChinese = true;
      if (matches) {
        matches.forEach((item) => {
          if (!/^[\u4e00-\u9fa5]+$/.test(item.slice(1))) {
            // 去掉前面的_，然后检查是否全是汉字
            is_AllChinese = false;
          }
        });
      }
      return is_AllChinese;
    },
    //处理级联选择器
    handleCommand(command) {
      // console.log(command, 'command');
      let val = `${command[0]}_${command[1]}`;
      this.selectValue = [];
      this.insertContent(val);
    },
    /**
     * @brief        : 获取光标位置，并追加内容
     * @Description  : 输入框内的任意位置，追加内容
     * @param         {*} val:追加的内容
     */
    async insertContent(val) {
      let dom = this.$refs.cm.$refs.mySql.codemirror.getCursor();
      // console.dir(dom);
      // console.log(dom, 'dom');
      this.$refs.cm.$refs.mySql.codemirror.replaceRange(val, { line: dom.line, ch: dom.ch });
      this.$refs.cm.$refs.mySql.codemirror.focus();
    },
    //保留没有对表达式隐藏的元素
    deleteLastElement(array) {
      const newArray = array.filter((item) => !item.isHideExpression);
      return newArray;
    },
    //替换中文符号为英文符号
    replaceChineseSymbols(str) {
      // console.log(str, 'str');
      if (str) {
        let replacedString = str.replace(/（|）|、/g, function (match) {
          switch (match) {
            case '（':
              return '(';
            case '）':
              return ')';
            case '、':
              return '/';
            default:
              return match;
          }
        });
        // console.log(replacedString, 'replacedString');
        return replacedString;
      } else {
        return '';
      }
    },
    setExpressionVisible(bol) {
      if (typeof bol !== 'boolean') {
        return;
      }
      this.expressionVisible = bol;
    },
    showPopover(v) {
      this.expressionValue = v;
      this.expressionVisible = !this.expressionVisible;
      // this.$nextTick(() => {
      //   this.$refs.expressionPopover.updatePopper();
      // });
    },
    reset() {
      this.expressionValue = '';
      this.expressionVisible = false;
    },
  },
};
</script>

<style lang="scss">
.databaseModelingOnLogic {
  padding: 5px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  //   color: #606266;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.CodeMirror-hints {
  z-index: 3000 !important;
}
</style>
<style lang="scss">
.popoverWrap {
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: 2;

  .popover {
    width: 522px;
    background: #fff;
    min-width: 150px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    padding: 12px;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    word-break: break-all;
  }
}
</style>
